<template>
	<div>
		<!-- this is search -->
		<arch-table
			v-if="searchResults.length >= 1"
			:arch-records="searchResults"
			:arch-fields="searchTableFields"
			:current-api-page="searchPage"
			:total-pages="totalPages"
			:total-entries='totalEntries'
			@prevClicked="searchPrev"
			@nextClicked="searchNext"
			@goToPageClicked="goToPageSearch"
			:current-data="currentData"
		></arch-table>
		<div
			v-else
		>
			<p class="no-results">Δεν βρέθηκαν αποτελέσματα</p>
		</div>
	</div>
</template>

<script>
import ArchTable from "@/components/archivedata/ArchTable.vue"
export default {
	name: "SearchTable",
	components: {
		ArchTable
	},
	props: {
		searchResults: {
			type: Array,
			required: true,
			default() {
				return []
			}
		},
		searchTableFields: {
			type: Array,
			required: true,
			default() {
				return []
			}
		},
		searchPage: {
			type: Number
		},
		totalPages: {
			type: Number
		},
		totalEntries: {
			type: Number
		},
		currentData: {
			type: [],
			default() {
				return []
			}
		}
	},
	methods: {
		searchNext() {
			this.$store.dispatch('nxtSearchPage')
		},
		searchPrev() {
			this.$store.dispatch('previousSearchPage')
		},
		goToPageSearch(pageNum) {
			// this.$store.dispatch('nxtSearchPage')
			// this.searchNext()
			this.$store.dispatch('goToSearchPage', pageNum)
		}
	}
}
</script>
