<template>
	<div>

		<div class="text-justify" v-if="(isSecondary === true)">
			<P>Η παραγωγή του φωτογραφικού αρχείου φάρων του Παναγιώτη Κ. Λασκαρίδη ξεκίνησε στα 1980. Λάτρης των
				θαλασσών και
				των περιηγήσεων, συλλέκτης άλλωστε ναυτικών αντικειμένων (<a
					href="https://digitalgallery.laskaridisfoundation.org"
					target="_blank">digitalgallery.laskaridisfoundation.org</a>) και κάτοχος μιας από τις πλουσιότερες
				βιβλιοθήκες
				περιηγητικών εκδόσεων (<a href="//travelogues.gr" target="_blank">travelogues.gr</a>), ο Π. Κ.
				Λασκαρίδης
				επιδόθηκε συστηματικά στη φωτογράφιση φάρων, από το Αιγαίο και το Ιόνιο Πέλαγος. Στην αρχή για
				προσωπικούς
				λόγους και στη συνέχεια με στόχο να συμπληρώσει το φωτογραφικό αρχείο που δημιουργούνταν, η συλλογή
				περιλαμβάνει
				σήμερα περισσότερες από 10.000 φωτογραφίες.</P>
			<P>Η ψηφιοποίηση του αρχείου υλοποιήθηκε στο πλαίσιο του Επιχειρησιακού Προγράμματος Ανταγωνιστικότητα,
				Επιχειρηματικότητα και Καινοτομία (ΕΣΠΑ 2014-2020) με συγχρηματοδότηση από το Ευρωπαϊκό Ταμείο
				Περιφερειακής
				Ανάπτυξης (ΕΤΠΑ) της Ευρωπαϊκής Ένωσης. Για λόγους οικονομίας εδώ παρουσιάζεται μέρος μόνο του αρχειακού
				υλικού.
				Ερευνητές, ταξιδευτές αλλά και το ευρύ κοινό μπορεί να έχει πρόσβαση στο σύνολο του αρχείου στους χώρους
				του
				Ιδρύματος Αικατερίνης Λασκαρίδη. </P>
			<p>Το αρχείο εμπλουτίζεται σταδιακά, με απώτερο στόχο να αποτελέσει κόμβο ενημέρωσης για το ελληνικό φαρικό
				σύστημα και ψηφίδα γνώσης για το παγκόσμιο. Καθώς οι φάροι και το φαρικό σύστημα συγκεντρώνουν μεγάλο
				ερευνητικό
				ενδιαφέρον—κορυφαίο παράδειγμα η βιβλιοθήκη του Esbjörn Hillberg, διαθέσιμη διαδικτυακά <a
					href="https://soa.ub.gu.se/en/special-collections/esbjorn-hillberg-lighthouse-collection"
					target="_blank">εδώ</a> (κι <a href="/esbjörn-hillbergs-fyrsamling.xlsx" target="_blank"
					download>εδώ</a> προς
				αποθήκευση στον υπολογιστή σας) — η παρούσα συλλογή φιλοδοξεί να συμπληρώσει την εικόνα για τους
				πετρόχτιστους
				φάρους της νοτιανατολικής Ευρώπης.</p>
			<p>Διαγωνισμοί φωτογραφίας, λημματογράφισης και άλλες δράσεις θα ανακοινώνονται στην ιστοσελίδα του
				Ιδρύματος, <a href="https://www.laskaridisfoundation.org/" target="_blank">laskaridisfoundation.org</a>
			</p>
		</div>
		<div class="text-justify" v-if="(this.$route.params.id === '38592')">
			<p>Η ψηφιοποίηση του αρχείου υλοποιήθηκε στο πλαίσιο του Επιχειρησιακού Προγράμματος Ανταγωνιστικότητα,
				Επιχειρηματικότητα και Καινοτομία (ΕΣΠΑ 2014-2020) με συγχρηματοδότηση από το Ευρωπαϊκό Ταμείο
				Περιφερειακής
				Ανάπτυξης (ΕΤΠΑ) της Ευρωπαϊκής Ένωσης.</p>
		</div>
		<!-- <h6>Συνολικά Αποτελέσματα: {{ totalEntries }}</h6>
		<h6
			class="owner"
			v-if="currentCollection"
			v-text=" 'Άρχείο: ' +  currentCollectionTitle"
		></h6> -->
		<b-container
			v-if="((archRecords.length > 1) || ((archRecords.length > 0) && (itemSet == 34033 || itemSet == 34034 || itemSet == 34605)))"
			ref="tableTop" fluid>
			<!-- Main table element -->
			<b-table show-empty small hover responsive stacked="lg" striped :items="archRecords" :fields="archFields"
				:current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn"
				:sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
				@filtered="onFiltered">
				<template v-slot:cell(title)="row">
					<div class="title-container">
						<a class="title-link" @click.prevent="goToProfile(row.item, row.index)">
							{{ row.item.title }}
						</a>
					</div>
				</template>

				<!-- <template v-slot:cell(pdflink)="row">
					<div class="title-container">
						<a class="title-link" :href="row.source"  @click.prevent="openSourceFile(row.item)" target="_blank">Προβολή</a>
					</div>
				</template> -->

				<template v-slot:cell(tags)="row">
					<div class="tags-container">
						<single-tag v-for="tag in row.item.tags" :key="tag.id" :row="row.index" :tag="tag"></single-tag>
					</div>

				</template>

				<template v-slot:cell(actions)="row">
					<div class="table-link">
						<a @click.prevent="galleryCall(row.item.id, row.item.title)" href="">
							<img v-if="row.item.thumbnail" :src="row.item.thumbnail" alt="">
							<img v-else src="../../assets/images/gallery.png" alt="">
						</a>
					</div>
				</template>

				<template v-slot:row-details="row">
					<b-card>
						<ul>
							<li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
						</ul>
					</b-card>
				</template>
			</b-table>

			<!-- Info modal -->
			<b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
				<pre>{{ infoModal.content }}</pre>
			</b-modal>
			<!-- User Interface controls -->
			<!-- <b-row class="centeringX">

				<b-col sm="2" md="2" class="my-1 pagination-navs">
					<button
						@click="prevClicked"
						class="btn btn-primary"
						:class="{'btn-secondary': isSecondary, 'disabled': currentApiPage === 1}"
					>
						<img
							width="18px"
							:disabled="false"
							src="../../assets/images/arrow_left.png" alt=""
						>
					</button>
					<span>
						{{ currentApiPage }} / {{ totalPages }}
					</span>
					<button
						@click="goToSearchPageClicked"
						class="btn btn-primary"
						:class="{'btn-secondary': isSecondary, 'disabled': archRecords.length < 12}"
					>
					<img
							width="18px"
							style="visibility: visible"
							src="../../assets/images/arrow_right.png" alt=""
						>
					</button>

					<button
						@click="goToSearchPageClicked(5)"
						class="btn btn-primary"
						:class="{'btn-secondary': isSecondary, 'disabled': archRecords.length < 12}"
					>
					5
					</button>
					<button
						@click="goToSearchPageClicked(10)"
						class="btn btn-primary"
						:class="{'btn-secondary': isSecondary, 'disabled': archRecords.length < 12}"
					>
					10
					</button>
				</b-col>
			</b-row> -->
			<b-row class="centering">
				<!-- <b-row class="my-1 pagination-navs">
					<button @click="prevClicked" class="btn btn-primary page-link"
						:class="{ 'btn-secondary': isSecondary, 'disabled': currentApiPage === 1 }">
						<img width="18px" :disabled="false" src="../../assets/images/arrow_left.png" alt="">
					</button>

					<span>
						<button v-for="page in pageNumbers" :key="page" @click="goToSearchPageClicked(page)"
							:class="{ 'btn btn-primary btn-p page-no': true, 'btn-secondary': isSecondary, 'active': currentApiPage === page }">
							{{ page }}
						</button>
					</span>

					<button @click="nextClicked" class="btn btn-primary"
						:class="{ 'btn-secondary': isSecondary, 'disabled': currentApiPage === totalPages }">
						<img width="18px" style="visibility: visible" src="../../assets/images/arrow_right.png" alt="">
					</button>
				</b-row> -->
			</b-row>
			<b-row class="centering">
				<b-row class="my-1 pagination-navs">
					<!-- Previous Button -->
					<button @click="prevClicked" class="btn btn-primary page-link"
						:class="{ 'btn-secondary': isSecondary, 'disabled': currentApiPage === 1 }">
						<img width="18px" src="../../assets/images/arrow_left.png" alt="Previous" />
					</button>

					<!-- Page Numbers -->
					<span>
						<button v-for="page in pageNumbers" :key="page"
							@click="page !== '...' && goToSearchPageClicked(page)" :class="{
								'btn btn-primary btn-p page-no': page !== '...',
								'btn-secondary': isSecondary,
								'active': currentApiPage === page,
								'disabled': page === '...'
							}">
							{{ page }}
						</button>
					</span>

					<!-- Next Button -->
					<button @click="nextClicked" class="btn btn-primary"
						:class="{ 'btn-secondary': isSecondary, 'disabled': currentApiPage === totalPages }">
						<img width="18px" src="../../assets/images/arrow_right.png" alt="Next" />
					</button>
				</b-row>
			</b-row>

		</b-container>
		<b-container v-if="((archRecords.length === 1) && (itemSet != 34033 && itemSet != 34034 && itemSet !== 34605))"
			ref="tableTop" fluid>
			<SingleEntry :key="archRecords[0].id" :id="archRecords[0].id" :title="archRecords[0].title"
				:general-folder-name="archRecords[0].generalFolderName"
				:general-folder-num="archRecords[0].generalFolderNum" :folder-number="archRecords[0].folderNumber"
				:file-number="archRecords[0].fileNumber" :subject="archRecords[0].subject"
				:profile-tags="archRecords[0].tags"></SingleEntry>
		</b-container>
		<!-- <arch-gallery
			v-if="archRecords.length != 1"
			:images="rowImages"
			:index="sliderIndex"
			@close="sliderIndex=null"
		></arch-gallery> -->
		<modal-gallery v-if="showModalGallery" :entry-title="rowTitle" :images-array="rowImages" :idx="sliderIndex"
			@close="showModalGallery = false"></modal-gallery>
	</div>
</template>

<script>
import ModalGallery from "@/components/ModalGallery.vue"
import SingleEntry from "@/components/SingleEntry.vue"
import SingleTag from "@/components/SingleTag.vue"
import { mapGetters } from "vuex"

export default {
	name: "ArchTable",
	components: {
		ModalGallery,
		SingleTag,
		SingleEntry
	},
	props: {
		archRecords: {
			type: Array,
			required: true,
			default() {
				return []
			}
		},
		archFields: {
			type: Array,
			required: true,
			default() {
				return []
			}
		},
		currentApiPage: {
			type: Number
		},
		totalPages: {
			type: Number
		},
		totalEntries: {
			type: Number
		},
		currentData: {
			type: [],
			default() {
				return []
			}
		}
	},
	data() {
		return {
			items: [],
			fields: [],
			rowImages: [],
			sliderIndex: null,
			totalRows: 1,
			currentPage: 1,
			perPage: 12,
			pageOptions: [5, 10, 15],
			sortBy: '',
			showModalGallery: false,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,
			filterOn: [],
			infoModal: {
				id: 'info-modal',
				title: '',
				content: ''
			},
			rowTitle: '',
			currentCollectionTitle: '',
			itemSet: 0,
			isSecondary: false
		}
	},
	computed: {
		...mapGetters({
			currentCollection: 'getCurrentSet'
		}),
		pageNumbers() {
			const pages = []
			const maxDisplayedPages = 3
			if (this.totalPages <= 5) {
				for (let i = 1; i <= this.totalPages; i++) {
					pages.push(i)
				}
				return pages
			}
			pages.push(1)
			if (this.currentApiPage >= maxDisplayedPages + 1) {
				pages.push('...')
			}
			let start = Math.max(2, this.currentApiPage - 1)
			let end = Math.min(this.totalPages - 1, this.currentApiPage + 1)
			for (let i = start; i <= end; i++) {
				pages.push(i)
			}
			if (this.currentApiPage < this.totalPages - maxDisplayedPages) {
				pages.push('...')
			}
			pages.push(this.totalPages)
			return pages
		},
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter(f => f.sortable)
				.map(f => {
					return { text: f.label, value: f.key }
				})
		},
		getRows() {
			return this.archRecords.length
		}
	},
	created() {
		// Set the initial number of items
		this.items = this.archRecords
		this.totalRows = this.archRecords.length
		if (parseInt(this.$route.params.id) === 34033 || parseInt(this.$route.params.id) === 34034 || parseInt(this.$route.params.id) === 34605) {
			this.itemSet = this.$route.params.id
		}
	},
	methods: {
		info(item, index, button) {
			this.infoModal.title = `Row index: ${index}`
			this.infoModal.content = JSON.stringify(item, null, 2)
			this.$root.$emit('bv::show::modal', this.infoModal.id, button)
		},
		resetInfoModal() {
			this.infoModal.title = ''
			this.infoModal.content = ''
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		openSourceFile(e) {
			// console.log("data ", e)
			// window.open("https://google.com", '_blank')
		},
		goToProfile(e, idx) {
			if (this.$route.name !== 'search') {
				this.$store.dispatch('applyItemSet', this.$route.params)
			} else {
				this.$store.dispatch('applyItemSet', 0)
			}
			if (this.itemSet > 0) {
				this.$router.push({ path: "/profile-detail/" + e.id })
			} else {
				// console.log("=====")
				// console.log(this.currentData)
				if (this.currentData) {
					if (this.currentData.isParent === 'parent, laskari' || this.currentData.isParent === 'laskari') {
						this.$router.push({ path: "/profile/" + e.id + "?isL=1" })
					} else {
						this.$router.push({ path: "/profile/" + e.id })
					}
				} else {
					this.$router.push({ path: "/profile/" + e.id })
				}
			}
		},
		galleryCall(itemId, title) {
			// this.$emit('showgallery', itemId)
			this.rowTitle = title
			this.$http
				.get(`${this.$store.state.rootUrl}media?item_id=${itemId}&per_page=500`)
				.then((response) => {
					this.rowImages = this.getImg(response.data)
					this.sliderIndex = 0
					this.showModalGallery = true
				})
		},
		getImg(res) {
			const imagesArray = []
			for (let i = 0; i < res.length; i++) {
				let single = { img: '', description: '' }
				single.img = res[i][`o:original_url`]
				single.description = res[i][`dcterms:description`] ? res[i][`dcterms:description`][0][`@value`] : ""
				imagesArray[i] = single
			}
			return imagesArray
		},
		prevClicked() {
			if (this.currentApiPage > 1) {
				this.scrollBack()
				this.$emit('prevClicked')
			}
		},
		nextClicked() {
			// alert(this.currentApiPage < this.totalPages)
			// alert(this.currentApiPage + " " + this.totalPages)
			if (this.archRecords.length === 12) {
				// if (this.currentApiPage < this.totalPages) {
				this.scrollBack()
				this.$emit('nextClicked')
			}
		},
		goToSearchPageClicked(pageNo) {
			// alert(this.currentApiPage < this.totalPages)
			// alert(this.currentApiPage + " " + this.totalPages + " | " + pageNo)
			// pageNo = 5 // pageNo > 0 ? pageNo : this.currentApiPage
			this.scrollBack()
			this.$emit('goToPageClicked', pageNo)
			/* if (this.archRecords.length === 12) {
				this.scrollBack()
				// alert("P np : " + pageNo)
				this.$emit('goToPageClicked', pageNo) // goToPageClicked
			} */
		},
		scrollBack() {
			let element = this.$refs.tableTop
			let fromTop = element.offsetTop - 250
			window.scrollTo({ top: fromTop, behavior: 'smooth' })
		},
		currentItemSetTitle() {
			const item = this.$store.getters['getCurrentSet']
			const item2 = this.$store.state.allItemSets
			if (item) {
				const curr = item2.filter(x => x.id === +item.id)
				this.currentCollectionTitle = curr[0] ? curr[0].title : ''
			} else {
				this.currentCollectionTitle = ''
			}
		}
	},
	mounted() {
		this.currentItemSetTitle()
		const validIdsSet = [42360]
		const idURL = parseInt(this.$route.params.id)
		if (validIdsSet.includes(idURL)) {
			this.isSecondary = true
		}
	},
	watch: {
		currentCollection(x, y) {
			this.currentItemSetTitle()
		}
	}
}
</script>

<style lang="scss" scoped>
h6 {
	text-align: left;
	padding: 2% 15px;
}

.title {
	width: 10%;
}

.title-container {
	cursor: pointer;
}

.title-container:hover {
	text-decoration: underline;
	text-shadow: 0px 1px #c7c7c7;
}

.disabled {
	background-color: $light-green;
	cursor: default;
}

.pagination-navs {
	display: inherit;
	justify-content: space-around;
	align-items: center;
	min-width: 200px;

	button {
		background-color: $tur-blue;
		padding: 4px 13px;
		border-radius: 0px;
	}

	button.disabled {
		background-color: $light-green;
	}
}

.table-link:nth-child(2) img {
	width: 14px;
	margin-left: 10px;
}

.owner {
	padding-top: 0;
}

.table-link img:first-child {
	box-shadow: 2px 4px 25px rgba(0, 0, 0, .1);
	border-radius: 2px;
	overflow: hidden;
	transition: all .2s linear;
}

.table-link img:first-child:hover {
	box-shadow: 2px 8px 45px rgba(0, 0, 0, .15);
	transform: translate3D(0, -2px, 0);
}

@media(min-width: 993px) {
	.table-link {
		margin: 40px 1%;

		img:first-child {
			width: 54px;

		}
	}

	.table-link:nth-child(2) img {
		width: 14px;
		margin-left: 10px;
	}
}

@media(max-width: 992px) {

	// .table.b-table.b-table-stacked-lg > tbody > tr > .actions > div{
	// 	display: inline-flex;
	// }

	.table-link {
		width: 28%;
		height: 43px;
		margin-top: 5%;
	}

	.table-link:first-child {
		margin-left: 52%;
		width: 80px;
	}

	.table-link:first-child img {
		width: 50px;
		margin-top: -17%;
	}

	.table-link:nth-child(2) img {
		width: 13px;
	}
}

.pagination-navs .page-no {
	padding: 4px 13px;
	margin: 8px;
}

.pagination-navs .btn-p {
	background: #FFFFFF;
	color: #000;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
	background: #95b136;
}

.pagination-navs .disabled {
	cursor: not-allowed;
	border: none;
}
</style>
